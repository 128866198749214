<template>
  <div class="w-full lg:w-6/12 mx-auto">
    <h1 class="font-bold mt-4 text-2xl mb-6">Data Sync</h1>
    <div class="box-style grid grid-cols-1 lg:grid-cols-1">
      <div class="col-span-1">
        <!-- Setting per module -->
        <div
          v-for="(module, index) in syncSettings"
          :key="index"
          class="flex p-5 items-center border-b border-gray-400"
        >
          <div class="flex-1 leading-tight">
            <h2 class="text-lg font-semibold m-0 capitalize">{{module.name}}</h2>
            <span class="font-light text-xs">Syncs every {{convertToMinutes(module.frequency)}} mins</span>
          </div>
          <div class="flex-initial p-2 border rounded-lg">
            <select name="" @change="updateSettings($event, module)" id="">
              <option :selected="getSelected(module.frequency, minutes)" v-for="(minutes, index) in minuteOptions" :key="index" :value="minutes">{{minutes}}</option>
              <!-- <option value="10">10</option>
              <option value="15">15</option> -->
            </select>
          </div>
        </div>
        <!-- End Setting per module -->
      </div>

      <!-- Sync History -->
      <div class="col-span-1 border-l border-gray-300 hidden">
        <h2 class="history-title px-5 py-4 text-lg font-extrabold m-0">
          Sync History
        </h2>
        <div class="my-3">
          <div class="flex p-5 items-center border-b border-gray-400">
            <div class="leading-tight">
              <h2 class="text-base font-normal m-0">
                Saved 12 records for module “Customer”
              </h2>
              <span class="font-light text-xs">Just now</span>
            </div>
          </div>
          <div class="flex p-5 items-center border-b border-gray-400">
            <div class="leading-tight">
              <h2 class="text-base font-normal m-0">
                Saved 12 records for module “Products”
              </h2>
              <span class="font-light text-xs">Just now</span>
            </div>
          </div>
        </div>
      </div>
      <!-- End Sync History -->
    </div>
  </div>
</template>

<script>
import { hasSyncStatus, hasMetaRecord} from '../../offline-module/falseDestination'
import { ModuleConfig } from "../../offline-module/config";
import { eventBus } from '../../../src/main.js'
export default {
  data() {
    return {
      syncSettings: [],
      minuteOptions: [2, 10, 15]
    };
  },
  async created() {
    let settings = (await this.$GlobalOfflineManager.getAll("syncsettings")) || [];
    settings = settings.filter(entry => !hasSyncStatus(entry, 'syncsettings') && !hasMetaRecord(entry, 'syncsettings'))
    console.log("SyncSettings: ", settings, ModuleConfig);
    if (settings.length > 0) {
      this.syncSettings = settings
      // debugger
    } else {
      this.syncSettings = Object.keys(ModuleConfig).map((module) => {
        return {
          name: module,
          frequency: 300000
        }
      })
    }
  },
  methods: {
    updateSettings(event, module) {
      if(module.name && module.frequency) {
        console.log('begin update:', event.target.value)
        const itemIndex = this.syncSettings.findIndex(syncmodule => syncmodule.name === module.name)
        this.syncSettings[itemIndex].frequency = this.convertToMilliseconds(event.target.value)
        console.log('updated syncSettings: ', this.syncSettings)

        this.$GlobalOfflineManager.db.collection('syncsettings').set(this.syncSettings).then(() => {
          eventBus.$emit("trac-alert", {
              message: `Sync Settings have been saved`,
          });
        })
      } else {
        console.error('Module must be string and frequency be above 0')
      }
    },
    convertToMilliseconds(minutes) {
      return parseInt(minutes) * 60000
    },
    convertToMinutes(milliseconds) {
      return parseInt(milliseconds) / 60000
    },
    getSelected(frequencyInMiliseconds = 0, minutes = 0) {
      return this.convertToMinutes(frequencyInMiliseconds) === minutes || false
      // does the converted minutes from frequency match the minutes provided?
      // if so return true, or return false
    }
  },
};
</script>
<style scoped>
.history-title {
  background: #effaff;
}
.box-style {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 15px;
}
</style>